import React, { useState, useEffect }  from "react"
import { getCurrentUser } from "../../utils/auth"
import * as styles from "./main.module.css"
import DonatorBalance from "../DonatorBalance"
import InfoBlock from "../info-block"
import DonationForm from "../DonationForm"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useNavigationDispatch } from "../navigation-context"
import withUserData from "../withUserData"
import { Link } from "gatsby"
import Alert from "react-bootstrap/Alert"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"


const TextContainer = styled.div`
  margin: 0;
  padding: 0.25rem;
`

const CenteredContainer = styled.div`
  text-align: center;
`

const RecipientsLink = styled.p`
  font-size: 0.7em;
`

const Donation = ({ search, location, userData, checkUserStatusFunc }) => {
  const { username, cookie } = getCurrentUser()
  const { r, a, s, sub } = search
  const [isFinished, setIsFinished] = useState(false);
  const [infoMessage, setInfoMessage] = useState(""); // eslint-disable-line
  const [additionalMessage, setAdditionalMessage] = useState(""); // eslint-disable-line
  const [errorMessage, setErrorMessage] = useState("");
  const [balance, setBalance] = useState(-1);
  const [donationSource, setDonationSource] = useState("");
  const [displayReturnLink, setDisplayReturnLink] = useState(false);

  const [userAllowedToDonate, setUserAllowedToDonate] = useState(true);
  const [donationWasCredited, setDonationWasCredited] = useState(false);

  useEffect(() => {
    // Only active and confirmed users are allowed to refill
    checkUserStatusFunc().then(async response => {
      setUserAllowedToDonate(response === 1);
    });
  }, [checkUserStatusFunc]);

  const dispatch = useNavigationDispatch();


  useEffect(() => {

    if (s === "referrer") {
      if (!document.referrer || document.referrer === "") {

        setDonationSource("Not provided")
        setDisplayReturnLink(false);
      } else {
        setDonationSource(document.referrer);
        setDisplayReturnLink(true);
      }
    } else {
      setDonationSource(s);
    }

    dispatch({type: 'donationpage', target: `${location.pathname}${location.search}`})

    if (userData && userData.Account_A) {
      setBalance(userData.Account_A.Balance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const thank_you_message = () => {
    if (isFinished) {
      return(
        <div className={"col"}>
          <Alert key="donationsuccessmess" variant="success">
            <Alert.Heading>Tack för din donation!</Alert.Heading>
            <p style={{ display: displayReturnLink && donationSource!=="Not provided" ? "block" : "none" }} className={["smaller"].join(" ")}>
              Du skickas strax tillbaka.
              Eller <Alert.Link href={donationSource}>klicka här</Alert.Link> om du inte vill vänta.
            </p>
          </Alert>
        </div>
      );
    }
  }


  const donation_credit_alert_element = () => {
    if (donationWasCredited) {
      return(
        <div className={"col"}>
          <Alert key="donationcreditmess" variant="warning" onClose={() => setDonationWasCredited(false)} dismissible>
            <Alert.Heading>Donation är  väntande</Alert.Heading>
            <p>
              Din behållning på kontot täcker inte det belopp du önskar donera
              och donationen är därför markerad som väntande.
            </p>
            <p>
              För att genomföra donationen var vänlig
              att <Alert.Link href="/app/refill">fylla på ditt MediaLinq-konto</Alert.Link>.
            </p>
          </Alert>
        </div>
      );
    }
  }

  const user_alert_element = () => {
    if (!userAllowedToDonate) {
      return(
        <div className={"col"}>
          <Alert key="errormess" variant="danger">
            <Alert.Heading>Donation är inte möjlig</Alert.Heading>
            <p>
              För att kunna donera måste du först bekräfta din epostadress genom att klicka
              på länken i det e-postmeddelande som skickades till din epostadress i samband med att
              du registrerade ditt konto på MediaLinq.
            </p>
            <p>
              Kontrollera ditt spam-filter om du inte har fått e-postmeddelandet.
            </p>
          </Alert>
        </div>
      );
    }
  }


  const donationSubmitted = (credited_amount, error) => {
    if (error) {
      setErrorMessage(error)
    } else {

      setDonationWasCredited(credited_amount > 0);

      setErrorMessage(undefined)
      setIsFinished(true);
      if (donationSource !== "Not provided") {
        if (displayReturnLink) {
          setTimeout(function() {
            window.location.replace(donationSource);
          }, 3000);
        } else {
          window.parent.postMessage("DonationCompleted", donationSource);
          window.postMessage("DonationCompleted", donationSource);
        }
      }
    }
  }

  const containers = (content) => {
    return (
      <div className={styles.outercontainer}>
        <div className={styles.container}>
          {content}
        </div>
      </div>
    )
  }

  if (!s || !r) {
    return (
      containers(<p>Artikel saknas</p>)
    )
  }

  if (!username) {
    return (
      containers(<p>Okänd användare</p>)
    )
  }

  const handleChangeBalance = val => {
    setBalance(val);
  }

  const donationpage_path = location.pathname+location.search;


  if (!userData || !userData.Account_A) {
    return (
      containers(
        <div>
          <div className={"p-3"}>
            {/*<DonatorHeader donator_name={username} />*/}
            <div style={{ display: "block" }} className={styles.errormessage}>
              Du är inloggad som mottagare och det är därför inte möjligt för dig
              att donera. För att kunna donera måste du logga
              in som donator.
            </div>
            <div className={"mt-3"}>
              <Link to="/app/profile/">Klicka här</Link> för att komma till din profilsida.
            </div>

          </div>
        </div>
      )
    )
  } else {

    return (
      containers(
        <Container>

          <CenteredContainer>

            {thank_you_message()}
            {user_alert_element()}
            {donation_credit_alert_element()}

          <div style={{ display: isFinished ? "none" : "block" }}>
            {/*<DonatorHeader donator_name={username} />*/}
            <TextContainer>
              <InfoBlock infotext={infoMessage}/>
            </TextContainer>
            <div
              style={{ display: errorMessage ? "block" : "none" }}
              className={styles.errormessage}
              dangerouslySetInnerHTML={{__html: errorMessage}}/>

            <div style={{ display: (balance < 0) ? "none" : "block" }}>
              <DonationForm enabled={userAllowedToDonate} postAction={donationSubmitted} amount={a} source={donationSource} recipient={r} sender={username}
                            cookie={cookie}/>
            </div>
            <InfoBlock infotext={additionalMessage}/>

            <DonatorBalance donationpage={donationpage_path} account_data={userData.Account_A} donator_name={username}
                            cookie={cookie} onChangeValue={handleChangeBalance}/>
            <RecipientsLink className={["mt-2"].join(" ")}><a  target="_blank" rel="noopener noreferrer" href={"https://netmediasystems.se/mottagarlista"}>Se vilka som har den gröna knappen eller MediaLinq-länken.</a></RecipientsLink>
          </div>
          </CenteredContainer>
        </Container>
      )
    )
  }
}

Donation.propTypes = {
  r: PropTypes.object,
  a: PropTypes.object,
}

export default withUserData(Donation)
